import { querySelectorNonNull } from "../../document"

export class AuthField {
    readonly ocAuthField: HTMLOcAuthFieldV1Element
    private readonly inputField: HTMLInputElement
    private readonly inputHint: HTMLElement
    private shouldShowError = false
    private error?: string
    private readonly hint: string

    constructor(onAuthField: HTMLOcAuthFieldV1Element) {
        this.ocAuthField = onAuthField
        this.inputField = querySelectorNonNull(onAuthField, "input")
        this.inputHint = querySelectorNonNull(onAuthField, "div[slot]")
        const dataHintAtt = this.inputHint.getAttribute("data-hint")
        this.hint = dataHintAtt != null ? dataHintAtt : ""
        const hintContent = this.inputHint.textContent != null ? this.inputHint.textContent : undefined
        this.error = onAuthField.variant == "error" ? hintContent : undefined
    }

    get value(): string {
        return this.inputField.value
    }
    set value(value: string) {
        this.inputField.value = value
    }

    public registerInlineValidationListener(inlineValidationListener: (abortSignal: AbortSignal) => void, validationVisibilityHandler?: () => boolean) {
        const visibilityHandler = validationVisibilityHandler ? validationVisibilityHandler : () => true
        const hasServerSideError = this.error != undefined
        let ignoreVisibilityHandler = hasServerSideError
        const onFocusIn = hasServerSideError || validationVisibilityHandler != undefined
        let abortController: AbortController
        const focusListener = () => {
            if(!this.shouldShowError && visibilityHandler()) {
                this.setShouldShowError(true)
            }
            const performValidationAndHandleVisibility = () => {
                if(abortController) {
                    abortController.abort()
                }
                abortController = new AbortController()
                inlineValidationListener(abortController.signal)
                if(!ignoreVisibilityHandler) {
                    this.setShouldShowError(visibilityHandler())
                }
            }
            this.registerEventListener("input", performValidationAndHandleVisibility)
            if(this.error == undefined) {
                performValidationAndHandleVisibility()
            }
        }
        this.registerEventListener(onFocusIn ? "focusin" : "focusout", focusListener)
        /*                                                          */
        this.registerEventListener("focusout", () => {
            ignoreVisibilityHandler = true
            this.setShouldShowError(true)
        })
    }

    private setShouldShowError(shouldShowError: boolean) {
        this.shouldShowError = shouldShowError
        this.updateValidationState()
    }

    public setValidationResult(error?: string) {
        this.error = error
        this.updateValidationState()
    }

    private updateValidationState() {
        if (!this.shouldShowError || this.error == undefined) {
            this.inputHint.slot = this.hint ? "hint" : "none"
            this.inputHint.textContent = this.hint
            this.inputField.setAttribute("aria-invalid", "false")
            this.ocAuthField.variant = "default"
        } else if (this.shouldShowError) {
            const errorVariant = "error"
            this.inputHint.slot = errorVariant
            this.inputHint.textContent = this.error
            this.inputField.setAttribute("aria-invalid", "true")
            this.ocAuthField.variant = errorVariant
        }
    }

    private registerEventListener(eventType: string, listener: () => void) {
        this.inputField.removeEventListener(eventType, listener)
        this.inputField.addEventListener(eventType, listener)
    }
}
